import APIRequest from './api';

export const Authenticate = (APIKey) => {
    return APIRequest({
        url: `Moderation/Authenticate`,
        method: "post",
        body: {
            authKey: APIKey
        }
    })
}
export const RunContent = (content) => {
    return APIRequest({
        url: `Moderation/RunContent`,
        method: "post",
        body: {
            content: content,
        }
    })
}