import React, { useState } from 'react';
import { Authenticate, RunContent } from '../api/ModerationAPI';
//import ReactQuill from 'react-quill';

const ContentInput = () => {
    const [content, setContent] = useState('');
    const [authKey, setAuthKey] = useState('');
    //const [responseStatus, setResponseStatus] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [displayMessage, setDisplayMsg] = useState('');
    const [selfHarmLevel, setSelfHarmLevel] = useState(0);
    const [violenceLevel, setViolenceLevel] = useState(0);
    const [sexualLevel, setSexualLevel] = useState(0);
    const [hateLevel, setHateLevel] = useState(0);
    const [politicalLevel, setPoliticalLevel] = useState(0);
    const [religionLevel, setReligionLevel] = useState(0);
    const [reasoning, setReasoning] = useState([]);
    const [reference, setReference] = useState('');
    const [sentiment, setSentiment] = useState('');
    const [success, setSuccess] = useState(false);
    const [hitBlockedContent, setHitBlockedContent] = useState(false);
    const [blockedContent, setBlockedContent] = useState([]);
    const [hiddenItems, setHiddenItems] = useState([]);
    const [hcmi, setHcmi] = useState(''); // hidden content matched item
    const [smi, setSmi] = useState('') // hidden content smaller matched item
    const [bannedWord, setBannedWord]= useState('') // hidden content banned word used for match

    //Reason messages
    const [shm, setShm] = useState('');
    const [vm, setVm] = useState('');
    const [sm, setSm] = useState('');
    const [hm, setHm] = useState('');
    const [pm, setPm] = useState('');
    const [rm, setRm] = useState('');

    const [returnedHash, setReturnedHash] = useState('')

    const clearItems = () => {
        setSelfHarmLevel(0);
        setViolenceLevel(0);
        setSexualLevel(0);
        setHateLevel(0);
        setPoliticalLevel(0);
        setReligionLevel(0);
        setReference('');
        setSentiment('');
        setSuccess(false);
        setHitBlockedContent(false);
        setErrorMsg('');

        setShm('');
        setVm('');
        setSm('');
        setHm('');
        setPm('');
        setRm('');

        setBlockedContent([]);
        setHiddenItems([]);
        setHcmi('')
        setSmi('')
        setBannedWord('')
        setReturnedHash('');
        setDisplayMsg('');
    }


    const BlockedListContent = () => {
        return (
            blockedContent.map((x) => (
                <p key={x}>{x}</p>
            ))
        )
    }
    const PotentialMatchContent = () => {
        return (
            hiddenItems.map((item) => (
                item.matchedCharsPercent >= 60 ? 
                    item.smallMatchedItem === '' ? <p>{item.matchedCharsPercent}% - Part of text flagged: {item.matchedItem} | Matched term: {item.bannedWord}</p>
                        : <p>{item.matchedCharsPercent}% Part of text flagged: {item.smallMatchedItem} | Matched term: {item.bannedWord}</p>
                    : <></>
            )
            )
        )
    }
    const HarmCategoryClass = (harmLevel) => {
        return harmLevel > 0 ? "harm-red" : "harm-none";
    }
    const submit = () => {
        clearItems();
        Authenticate(authKey).then(response => {
            if (!response.success) {
                clearItems();
                setSuccess(false)
                setErrorMsg(response.message)
                setDisplayMsg(response.message)
                console.log('Invalid API Token')
                return;
            }
            RunContent(content).then(result => {
                if (!result.success) {
                    clearItems();
                    setErrorMsg(result.message)
                    setSuccess(false)
                    setDisplayMsg(result.message)
                    return;
                }
                else {
                    if (result.hitBlockedContent === true) {
                        setSuccess(true)
                        setDisplayMsg(result.message)
                        setHitBlockedContent(true)
                        setBlockedContent(result.blockedContent)
                        setSelfHarmLevel(result.finalSelfHarm);
                        setViolenceLevel(result.finalViolence);
                        setSexualLevel(result.finalSexual);
                        setHateLevel(result.finalHate);
                        setReligionLevel(result.oaiReligion);
                        setPoliticalLevel(result.oaiPolitical);
                        setReasoning(result.oaiReasoning);
                        setReference(result.oaiReference);
                        setSentiment(result.oaiSentiment);
                        setReturnedHash(result.contentHash);
                        setReturnedHash(result.contentHash);
                        setVm(result.oaiReasoning[0]);
                        setShm(result.oaiReasoning[1]);
                        setSm(result.oaiReasoning[2]);
                        setRm(result.oaiReasoning[3]);
                        setPm(result.oaiReasoning[4]);
                        setHm(result.oaiReasoning[5]);
                        setHiddenItems(result.hiddenContent)
                        return;
                    }
                   
                    setSuccess(true)
                    setDisplayMsg('Content results below:')
                    setSelfHarmLevel(result.finalSelfHarm);
                    setViolenceLevel(result.finalViolence);
                    setSexualLevel(result.finalSexual);
                    setHateLevel(result.finalHate);
                    setReligionLevel(result.oaiReligion);
                    setPoliticalLevel(result.oaiPolitical);
                    setReasoning(result.oaiReasoning);
                    setReference(result.oaiReference);
                    setSentiment(result.oaiSentiment);
                    setReturnedHash(result.contentHash);
                    setReturnedHash(result.contentHash);

                    setVm(result.oaiReasoning[0]);
                    setShm(result.oaiReasoning[1]);
                    setSm(result.oaiReasoning[2]);
                    setRm(result.oaiReasoning[3]);
                    setPm(result.oaiReasoning[4]);
                    setHm(result.oaiReasoning[5]);
                    setHiddenItems(result.hiddenContent)
                    return
                }
            });
        });
    }
    return (
        
        <div>
            <form>
                <div class="form-group" >
                    <label for="authKey">API Key:</label>
                    <input type="text" class="form-control" id="authKeyInput" value={authKey} onChange={(e) => setAuthKey(e.target.value)} />
                </div>
                <div class="form-group">
                    <label for="contentText">Content:</label>
                    <textarea class="form-control" value={content} onChange={(e) => setContent(e.target.value)} rows="3"></textarea>
                </div>
                <br></br>
            </form>
            <button type="submit" class="btn btn-primary" onClick={() => submit()}>Submit</button>
            <br></br>
            <div class="form-group">

                <br></br>
                <h3>Content Moderation Scoring:</h3>
                <i>Categories are given a severity score from 0-7.  Zero indicates no detection, or fair use in that category. Scores increasing from one to seven indicate that the content is contextually related to the category all the way up to being classified as extreme in that category. If explanations for the categorical scorings are available, they will be provided inline.</i>
                <br></br>
                <br></br>
                {success
                    ?
                    <div class="results">
                        <p class="result-label">{displayMessage}</p>
                        {hitBlockedContent === true &&
                            <>
                            <br></br>
                            <div>
                                {blockedContent.length > 0 ? <b>Absolute derogatory content found:</b> : null}
                                {blockedContent.length > 0 ? BlockedListContent() : null}
                            </div>
                            <div>
                                {hiddenItems.length > 0 ? <b>Potentially derogatory content found:</b> : null}
                                {hiddenItems.length > 0 ? PotentialMatchContent() : null}
                            </div>
                            </>
                        }

                        <hr></hr>
                        <p class="result-hash">Content Hash: {returnedHash}</p>
                        <div class="result-table">
                            <div class="row">
                                <div class="col-sm-3 result-label"> Violence: </div>
                                <div class={"col-sm-1 " + (HarmCategoryClass(violenceLevel))}> {violenceLevel} </div>
                                <div class="col-sm-8"> {violenceLevel > 0 ? vm : null} </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 result-label"> Self Harm: </div>
                                <div class={"col-sm-1 " + (HarmCategoryClass(selfHarmLevel))}> {selfHarmLevel} </div>
                                <div class="col-sm-8"> {selfHarmLevel > 0 ? shm : null} </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 result-label"> Sexual: </div>
                                <div class={"col-sm-1 " + (HarmCategoryClass(sexualLevel))}> {sexualLevel} </div>
                                <div class="col-sm-8"> {sexualLevel > 0 ? sm : null} </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 result-label"> Hate: </div>
                                <div class={"col-sm-1 " + (HarmCategoryClass(hateLevel))}> {hateLevel} </div>
                                <div class="col-sm-8"> {hateLevel > 0 ? hm : null} </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 result-label"> Political: </div>
                                <div class={"col-sm-1"}> {politicalLevel} </div>
                                <div class="col-sm-8">
                                    {politicalLevel > 0 ? pm  : null}
                                    {sentiment !== '' ? <div class="result-label">Sentiment: {sentiment}</div> : null}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 result-label"> Religious: </div>
                                <div class="col-sm-1"> {religionLevel} </div>
                                <div class="col-sm-8"> {religionLevel > 0 ? rm : null} </div>
                            </div>
                            <hr></hr>
                            <div class="row">
                                <div class="col-sm-3 result-label"> Reference: </div>
                                <div class="col-sm-1">  </div>
                                <div class="col-sm-8"> {reference !== '' ? reference : null} </div>
                            </div>
                        </div>
                        <br></br>

                    </div>
                    :
                    <div class="error-message">
                        <br></br>
                        {errorMsg}
                    </div>
                    
                }
            </div>

        </div>

    )
};
export default ContentInput;