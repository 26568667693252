const APIRequest = (params) => {
    return new Promise((resolve, reject) => {
        let weaverToken = localStorage.getItem("weaver-token");
        if (weaverToken === null)
            weaverToken = sessionStorage.getItem("weaver-token");

        const headers = {
            'Accept': params.respType === 'blob' ? 'blob' : 'application/json',
            'Content-Type': 'application/json'
        };

        if (weaverToken !== null)
            headers['Authorization'] = `Bearer ${weaverToken}`

        fetch(`/api/${params.url}`, {
            method: params.method,
            headers: headers,
            body: JSON.stringify(params.body)
        }).then(response => {
            if (response.status === 401) {
                if (!params.global === undefined) {
                    params.global.setAuthenticated(false);
                    params.global.setLoading(false);
                }
                reject(response);
            }
            if (!response.ok) {
                throw Error(response)
            }
            if (params.respType === "blob") {
                return response.blob();
            }
            if (params.respType === "raw") {
                return response;
            }
            return response.json();
        }).then(data => {
            resolve(data);
        }).catch(error => {
            reject(error);
        });
    });
}
export default APIRequest;