//import { Counter } from "./components/Counter";
//import { FetchData } from "./components/FetchData";
//import { Home } from "./components/Home";
import ContentInput from "./components/ContentInput";

const AppRoutes = [
    {
        index: true,
        element: <ContentInput />
    }
  //},
  //{
  //  path: '/counter',
  //  element: <Counter />
  //},
  //{
  //  path: '/fetch-data',
  //  element: <FetchData />
  //}
];

export default AppRoutes;
